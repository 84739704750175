import CartSwatches from './CartSwatches';
import cn from 'classnames';
import { fetchAddOnProductsByHandle } from '../../helpers/storefront';
import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';
import { useEffect, useState } from 'preact/hooks';

const CartCrossSellProduct = ({ product }) => {
  const [currentProduct, setCurrentProduct] = useState(product);
  const [selectedVariant, setSelectedVariant] = useState();
  const [swatches, setSwatches] = useState([]);
  const { addLineItem } = useCartContext();
  const [productTitle, setProductTitle] = useState(product.title);
  const [showSwatches, setShowSwatches] = useState(true);

  useEffect(() => {
    currentProduct.options.forEach(option => {
      if (option.name === 'Size' && option.values.length === 1) {
        setSelectedVariant(currentProduct.variants[0]);
      } else {
        setSelectedVariant(undefined);
      }
    });
  }, [currentProduct]);

  useEffect(() => {
    const getProductMetafields = async () => {
      const response = await fetchAddOnProductsByHandle(product.handle);
      if (
        response?.data?.product?.productGroup?.reference?.productList
          ?.references
      ) {
        setSwatches(
          response.data.product.productGroup.reference.productList.references
            .nodes,
        );
      }
      if (response?.data?.product?.altProductTitle) {
        setProductTitle(response.data.product.altProductTitle.value);
      }

      if (response?.data?.product?.productGroup?.reference?.hideSwatches) {
        setShowSwatches(
          response.data.product.productGroup.reference.hideSwatches.value !=
            'true',
        );
      }
    };

    getProductMetafields();
  }, [product]);

  const setCurrentProductByIndex = index => {
    const newProduct = swatches[index];

    setCurrentProduct({
      handle: newProduct.handle,
      id: newProduct.id,
      url: `/products/${newProduct.handle}`,
      variants: newProduct.variants.nodes.map(node => ({
        id:
          typeof node.id === 'string'
            ? parseInt(node.id.split('/').pop())
            : node.id,
        available: node.availableForSale,
        option2: node.selectedOptions[1].value,
      })),
      images: [newProduct.featuredImage.url],
      options: newProduct.options,
    });
  };

  if (!currentProduct) {
    return null;
  }

  const cross_sell_image = currentProduct?.images?.[0]
    ? currentProduct.images[0]
    : '';

  const handleClick = () => {
    if (selectedVariant === undefined) {
      return;
    }

    addLineItem({
      items: [
        {
          id: selectedVariant.id,
          quantity: 1,
        },
      ],
    });
  };

  return (
    <div
      data-product-id={currentProduct.id}
      class="flex items-center gap-4 px-4 py-4"
    >
      <a
        href={currentProduct.url}
        class="block aspect-product-item w-32 sm:w-44"
      >
        <img src={cross_sell_image} class="h-full w-full object-cover" />
      </a>

      <div class="flex flex-1 flex-col self-stretch">
        <h5 class="type-body-large mb-2">{productTitle}</h5>

        {showSwatches && (
          <div class="mb-4">
            <CartSwatches
              swatches={swatches}
              product={currentProduct}
              setCurrentProductByIndex={setCurrentProductByIndex}
            />
          </div>
        )}

        <div class="mb-2 grid grid-cols-2 gap-2">
          {currentProduct.variants.map(variant => (
            <div key={variant.id}>
              <input
                id={`cart-${variant.id}`}
                type="radio"
                value={variant.id}
                required
                onChange={() => setSelectedVariant(variant)}
                checked={variant.id === selectedVariant?.id}
                class="peer hidden"
              />
              <label
                for={`cart-${variant.id}`}
                class={cn(
                  'type-body-small type-captions-small peer-checked:type-bold block flex-1 cursor-pointer rounded-sm border border-primary-1/20 px-2 py-2 text-center peer-checked:bg-primary-1 peer-checked:text-white',
                )}
              >
                {variant.option2}
              </label>
            </div>
          ))}
        </div>

        <button
          onClick={handleClick}
          class="button button--primary button--small mt-auto flex w-full items-center justify-center gap-2"
          disabled={!selectedVariant?.available}
        >
          <span>
            {!selectedVariant
              ? getTranslation('mini_cart.add')
              : !selectedVariant.available
              ? getTranslation('mini_cart.sold_out')
              : getTranslation('mini_cart.add')}{' '}
          </span>
          {selectedVariant && (
            <span> - {formatMoney(product.price, theme.moneyFormat)}</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default CartCrossSellProduct;
