import { initCarousel } from '../helpers/carousel';
import { unitlessBreakpoints } from '../helpers/breakpoints';

export default class DynamicImageGallery {
  constructor(section) {
    this.el = section.el;

    initCarousel(this.el, {
      perPage: 1,
      breakpoints: {
        [unitlessBreakpoints.md]: {
          destroy: true,
        },
      },
    });
  }
}
