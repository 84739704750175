import { useEffect, useRef, useState } from 'preact/hooks';

const useEmptyPortal = container => {
  const initialHtml = useRef(container?.innerHTML);
  const [emptied, setEmptied] = useState(false);

  useEffect(() => {
    if (!emptied && container) {
      container.innerHTML = '';
      setEmptied(true);
    }
  }, [emptied]);

  return [emptied, initialHtml.current];
};

export default useEmptyPortal;
