import * as cartApi from '../../helpers/cartApi';
import Splide from '@splidejs/splide';
import { getTranslation } from '../../helpers';
import quickShop from './ProductQuickShop';
import { unitlessBreakpoints } from '../../helpers';
import { updateReactCart } from '../../preact/helpers/utilities';

const selectors = {
  quickShopTrigger: '[data-quickshop-trigger]',
  addToCartButton: '[data-add-to-cart]',
  productCardCarousel: '[data-product-card-carousel]',
  productCardImages: '[data-product-card-images]',
  swatchLink: '[data-swatch-link]',
  productLink: '[data-product-link]',
  moreSwatches: '[data-more-swatches]',
  productTitle: '[data-product-title]',
  disableMobileCarousel: '[data-disable-mobile-carousel]',
  disableCarousel: '[data-disable-carousel]',
  price: '[data-product-price]',
  comparePrice: '[data-product-compare-price]',
};

/**
 * A single product item in a collection / list of products
 *
 * @export
 * @class ProductItem
 */
export default class ProductItem {
  /**
   * Creates an instance of ProductItem.
   * @param {HTMLElement} el
   */
  constructor(el) {
    this.el = el;

    this._initQuickShop();
    const addToCartButton = this.el.querySelector(selectors.addToCartButton);
    if (addToCartButton) {
      addToCartButton.addEventListener('click', e => {
        this._addToCart(e.target.dataset.addToCart);
      });
    }

    this.productCardCarousel = this.el.querySelector(
      selectors.productCardCarousel,
    );
    if (this.productCardCarousel) {
      this._initCarousel();
    }

    this._initSwatches();
  }

  _initSwatches() {
    const swatchLinks = this.el.querySelectorAll(selectors.swatchLink);

    // Functionality for showing more swatches on the plus click. Was removed in favor of just linking to PDP on the more swatch click
    // const moreSwatches = this.el.querySelector(selectors.moreSwatches);
    // if (moreSwatches) {
    //   moreSwatches.addEventListener('click', () => {
    //     moreSwatches.classList.add('hidden');
    //     swatchLinks.forEach(swatch => {
    //       swatch.classList.remove('hidden', 'md:hidden');
    //     });
    //   });
    // }

    const featuredImages = this.el.querySelectorAll(
      `${selectors.productCardImages} img`,
    );
    const productLinks = this.el.querySelectorAll(selectors.productLink);
    const addToCartButton = this.el.querySelector(selectors.addToCartButton);

    swatchLinks.forEach(swatch => {
      swatch.addEventListener('click', () => {
        // Set the product card back to the first image
        if (this.productCardCarousel) {
          this.productCardCarousel.go(0);
        }

        // Remove product hash and change product URL for Quickshop
        delete this.el.dataset.productHash;
        this.el.dataset.productUrl = swatch.dataset.swatchUrl;

        // Update add to cart ID
        if (addToCartButton) {
          addToCartButton.dataset.addToCart = swatch.dataset.swatchId;
          if (swatch.dataset.swatchAvailable === 'true') {
            addToCartButton.removeAttribute('disabled');
            addToCartButton.innerHTML = getTranslation('products.add_to_cart');
          } else {
            addToCartButton.setAttribute('disabled', true);
            addToCartButton.innerHTML = getTranslation('products.sold_out');
          }
        }

        // Remove all active state
        swatchLinks.forEach(sib => {
          sib.classList.remove('active-swatch');
        });
        // Add active state to selected swatch
        swatch.classList.add('active-swatch');

        // Update product title
        const productTitle = this.el.querySelector(selectors.productTitle);
        productTitle.innerHTML = swatch.dataset.swatchTitle;

        // Update product card links
        productLinks.forEach(link => {
          link.setAttribute('href', swatch.dataset.swatchUrl);
        });

        // Update product price and product compare price
        const price = this.el.querySelector(selectors.price);
        const comparePrice = this.el.querySelector(selectors.comparePrice);
        price.innerHTML = swatch.dataset.swatchPrice;
        if (comparePrice) {
          comparePrice.innerHTML = swatch.dataset.swatchComparePrice;
        }

        // This code doesn't work on 127.0.0.1 for some reason.
        // We switch out the primary image first for load speed, and defer the
        // other two images until after the first one is loaded
        featuredImages[0].addEventListener('load', () => {
          const swatchImageTwo = swatch.dataset.swatchImageTwo;
          featuredImages[1].setAttribute('src', swatchImageTwo);
          featuredImages[1].removeAttribute('srcset');
          featuredImages[1].removeAttribute('sizes');

          if (featuredImages[2]) {
            const swatchImageThree = swatch.dataset.swatchImageThree;
            featuredImages[2].setAttribute('src', swatchImageThree);
            featuredImages[2].removeAttribute('srcset');
            featuredImages[2].removeAttribute('sizes');
          }
        });

        const swatchImageOne = swatch.dataset.swatchImageOne;
        featuredImages[0].setAttribute('src', swatchImageOne);
        featuredImages[0].removeAttribute('srcset');
        featuredImages[0].removeAttribute('sizes');
      });
    });
  }

  /**
   * Initialize primary image carousel
   */
  _initCarousel() {
    const disableCarousel = this.el.dataset.disableCarousel === 'true';
    const disableMobileCarousel =
      this.el.dataset.disableMobileCarousel === 'true';

    this.productCardCarousel = new Splide(this.productCardCarousel, {
      perPage: 1,
      pagination: true,
      rewind: true,
      speed: 1000,
      flickPower: 100,
      arrows: false,
      classes: {
        pagination: 'splide__pagination splide__pagination--product-item',
      },
      mediaQuery: 'min',
      destroy: disableMobileCarousel,
      breakpoints: {
        [unitlessBreakpoints.md]: {
          arrows: true,
          destroy: disableCarousel,
        },
      },
    });

    this.productCardCarousel.mount();
  }

  /**
   * Bind quickshop to a trigger
   */
  _initQuickShop() {
    const trigger = this.el.querySelector(selectors.quickShopTrigger);

    if (!trigger) {
      return;
    }

    trigger.addEventListener('click', () => {
      const { productUrl, productHash } = this.el.dataset;

      quickShop.open(productUrl, productHash);
    });
  }

  /**
   * This function is called whenever the user submits the form
   *
   * @param {*} event
   */
  _addToCart = id => {
    cartApi
      .add({
        items: [
          {
            id,
            quantity: 1,
          },
        ],
      })
      .then(() => {
        updateReactCart(true);
      })
      .catch(response => this._handleCartApiError(response));
  };

  /**
   * Generic error handler for cart api
   * Example: 422 response when trying to add a product whose total stock is already in cart
   *
   * @param {Object} response The response from Shopify
   */
  _handleCartApiError = response => {
    // eslint-disable-next-line no-console
    console.error(response);
  };
}
