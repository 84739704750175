/**
 * Fetch wrapper to be used by functions below.
 *
 * @param {string} url Path of the Shopify cart endpoint
 * @param {Object} params Request Parameters
 */
function request(url, params = {}) {
  /*
    Request headers are not very intuitive and seem to break the request if changed.
    References:
    https://community.shopify.com/c/Shopify-Design/AJAX-POST-cart-add-js-NEVER-returns-422-only-200-OK-on/td-p/375736
  */
  const paramsWithHeaders = Object.assign(
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    params,
  );

  return fetch(Shopify.routes.root + url, paramsWithHeaders).then(
    handleResponse,
  );
}

/**
 * Error handler for request().
 *
 * @param {Object} response
 * @returns {string} the error message
 */
async function handleResponse(response) {
  const responseData = await response.json();

  if (!response.ok) {
    throw responseData;
  }

  return responseData;
}

/**
 * Add an item to the cart via form submission
 *
 * @param {Object} data - The product variant to add
 * - Can be either a DOM element of the product form or an object
 *   representing the variant: { quantity, id, properties }
 * @returns {Object} Promise
 * - data returned in the response is the added cart item
 */
function add(data) {
  const formData = data.nodeName
    ? Object.fromEntries(new FormData(data))
    : data;

  const params = {
    method: 'POST',
    body: JSON.stringify(formData),
  };

  return request('cart/add.js', params);
}

/**
 * Change a cart item
 *
 * @param  {number} id - The product ID
 * @param  {number} quantity - the quantity to adjust
 * @returns {Object} Promise
 * - data returned in the response is the updated cart
 */
function change(id, quantity) {
  const params = {
    method: 'POST',
    body: JSON.stringify({ id, quantity }),
  };

  return request('cart/change.js', params);
}

/**
 * Remove a cart item
 *
 * @param  {number} id - The cart item to remove
 * @returns {Object} Promise
 * - data returned in the response is the removed item
 */
function remove(id) {
  return change(id, 0);
}

/**
 * Clear the cart
 *
 * @returns {Object} Promise
 * - data returned in the response is the empty cart.
 * - note that cart attributes and notes are NOT removed.
 */
function clear() {
  const params = { method: 'POST' };

  return request('cart/clear.js', params);
}

/**
 * Get a json representation of the cart
 *
 * @returns {Object} Promise
 * - data returned in the response is the cart
 */
function get() {
  return request('cart.js');
}

/**
 * Update the cart
 * Use this to change cart attributes, the cart note, and quantities of line items in the cart
 *
 * @param  {Object} data The cart items to update
 * @returns {Object} Promise
 * - data returned in the response is the cart
 */
function update(data) {
  const formData = data.nodeName
    ? Object.fromEntries(new FormData(data))
    : data;

  const params = {
    method: 'POST',
    body: JSON.stringify(formData),
  };

  return request('cart/update.js', params);
}

export { add, change, remove, clear, get, update };
