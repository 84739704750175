// Forked from https://github.com/pixelunion/shopify-asyncview v4.0.0
import JSON5 from 'json5';

const deferred = {};

export default class AsyncView {
  /**
   * Load the resource given by the provided URL into the provided view
   *
   *
   * @param {string} url - The url of the resource to load
   * @param {object} [query] - An object containing additional query parameters of the URL
   * @param {string} [query.view] - A query parameter indicating which view to load
   * @param {array} [query.sections] - A query parameter indicating which section(s) to load
   * @param {object} [options] - Config options
   * @param {string} [options.hash] - A hash used to cache content
   * @param {object} [options.query] - Additional parameters for the query
   * @returns {Promise} - A promise that contains the templates data-data and data-html attributes
   */
  static load(url, query = {}, options = {}) {
    const querylessUrl = url.replace(/\?[^#]+/, '');
    const queryParamsString = new RegExp(/.+\?([^#]+)/).exec(url);
    let queryParams = query;
    const isSection = query.sections && query.sections.length > 0;
    const contentType = isSection ? 'application/json' : 'text/html';

    if ('query' in options) {
      queryParams = {
        ...queryParams,
        ...options.query,
      };
    }

    if (queryParamsString && queryParamsString.length >= 2) {
      queryParamsString[1].split('&').forEach(param => {
        const [key, value] = param.split('=');

        queryParams[key] = value;
      });
    }

    // NOTE: We're adding an additional timestamp to the query.
    // This is to prevent certain browsers from returning cached
    // versions of the url we are requesting.
    // See this PR for more info: https://github.com/pixelunion/shopify-asyncview/pull/4
    const cachebustingParams = {
      ...queryParams,
      _: new Date().getTime(),
    };

    const hashUrl = querylessUrl.replace(
      /([^#]+)(.*)/,
      (match, address, hash) =>
        `${address}?${Object.keys(queryParams)
          .sort()
          .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
          .join('&')}${hash}`,
    );
    const requestUrl = querylessUrl.replace(
      /([^#]+)(.*)/,
      (match, address, hash) =>
        `${address}?${Object.keys(cachebustingParams)
          .sort()
          .map(key => `${key}=${encodeURIComponent(cachebustingParams[key])}`)
          .join('&')}${hash}`,
    );

    const promise = new Promise((resolve, reject) => {
      let sessionData;
      if (hashUrl in deferred) {
        resolve(deferred[hashUrl]);
        return;
      }

      if (options.hash) {
        sessionData = sessionStorage.getItem(hashUrl);

        if (sessionData) {
          const deserialized = JSON5.parse(sessionData);

          if (options.hash === deserialized.options.hash) {
            delete deferred[hashUrl];
            resolve(deserialized);
            return;
          }
        }
      }

      const request = fetch(requestUrl, {
        headers: {
          'Content-Type': contentType,
        },
      })
        .then(response => {
          if (isSection) {
            return response.json();
          }
          return response.text();
        })
        .then(body => {
          if (isSection) {
            // Add the original request options to the top-level of the response object
            // to check against future requests. This is primarily to check the hash value.
            const response = { options };
            Object.keys(body).forEach(section => {
              const { newOptions, html, data } = this.parseResponse(
                body[section],
              );
              response[section] = { options: newOptions, html, data };
            });

            if (options.hash) {
              try {
                sessionStorage.setItem(
                  hashUrl,
                  JSON5.stringify({ ...response }),
                );
              } catch (error) {
                console.error(error);
              }
            }

            delete deferred[hashUrl];
            resolve({ ...response });
          } else {
            const { newOptions, html, data } = this.parseResponse(body);
            if (options.hash) {
              try {
                sessionStorage.setItem(
                  hashUrl,
                  JSON5.stringify({ options: newOptions, html, data }),
                );
              } catch (error) {
                console.error(error);
              }
            }
            delete deferred[hashUrl];
            resolve({ options: newOptions, html, data });
          }
        })
        .catch(error => {
          delete deferred[hashUrl];
          reject(error);
        });

      deferred[hashUrl] = request;
    });

    return promise;
  }

  static parseResponse(response) {
    const parser = new DOMParser();
    const el = parser.parseFromString(response, 'text/html');

    let newOptions = {};
    const optionsEl = el.querySelector('[data-options]');

    if (optionsEl && optionsEl.innerHTML) {
      newOptions = JSON5.parse(optionsEl.innerHTML);
    }

    const htmlEls = el.querySelectorAll('[data-html]');

    let newHtml = {};

    if (htmlEls.length === 1 && htmlEls[0].getAttribute('data-html') === '') {
      newHtml = htmlEls[0].innerHTML;
    } else {
      for (let i = 0; i < htmlEls.length; i++) {
        newHtml[htmlEls[i].getAttribute('data-html')] = htmlEls[i].innerHTML;
      }
    }

    // Return the standard API response (the entire section markup)
    // if section isn't using the data-html syntax
    if (Object.keys(newHtml).length === 0) {
      newHtml = response;
    }

    const dataEls = el.querySelectorAll('[data-data]');

    let newData = {};

    if (dataEls.length === 1 && dataEls[0].getAttribute('data-data') === '') {
      newData = JSON5.parse(dataEls[0].innerHTML);
    } else {
      for (let i = 0; i < dataEls.length; i++) {
        newData[dataEls[i].getAttribute('data-data')] = JSON5.parse(
          dataEls[i].innerHTML,
        );
      }
    }

    return {
      newOptions,
      html: newHtml,
      data: newData,
    };
  }
}
