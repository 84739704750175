import Video from '../components/Video';

export default class DynamicVideo {
  constructor(section) {
    this.el = section.el;
    this.initVideo();
  }

  initVideo() {
    const videoEl = this.el.querySelector('[data-video]');
    let isAutoplay = false;

    this.hasPlayed = false;

    if (videoEl) {
      this.video = new Video(videoEl);
      isAutoplay = videoEl.dataset.autoplay;
    }

    if (this.video && isAutoplay === 'true') {
      const thresholds = { play: 0.5, pause: 0.2 };

      this.playPauseObserver = new IntersectionObserver(
        entries => {
          const { intersectionRatio, isIntersecting } = entries[0];

          if (
            intersectionRatio >= thresholds.play &&
            isIntersecting &&
            !this.hasPlayed
          ) {
            // videoEl has just scrolled into view and is at least 50% visible: play video
            this.video._autoplay();
            this.hasPlayed = true;
          } else if (
            intersectionRatio <= thresholds.pause &&
            isIntersecting === false
          ) {
            // videoEl has scrolled out of view and is less than 20% visible: pause video
            this.video._onPauseClick();
          }
        },
        {
          threshold: [thresholds.pause, thresholds.play],
        },
      );

      this.playPauseObserver.observe(videoEl);
    }
  }

  onSectionUnload() {
    if (this.video) {
      this.video.unload();
    }

    if (this.playPauseObserver) {
      this.playPauseObserver.disconnect();
    }
  }
}
