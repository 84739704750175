import JSON5 from 'json5';
import Product from './Product';
import { reRunScripts } from '../../helpers/utilities';

const selectors = {
  complementaryProductLink: '[data-comp-product-link]',
  product: '[data-product]',
  complementaryProductTitle: '[data-comp-product-title]',
  moreComplementaries: '[data-more-complementary]',
  syncSection: '[data-product-sync]',
};

/**
 * Async product switching functionality
 *
 * @export
 * @class ProductAsyncSwitcher
 */
export default class ProductAsyncSwitcher {
  constructor(el, sectionId) {
    this.el = el;
    this.sectionId = sectionId;

    const complementaryProductLinks = el.querySelectorAll(
      selectors.complementaryProductLink,
    );
    complementaryProductLinks.forEach(link => {
      const handle = link.dataset.compProductLink;
      const collectionHandle = link.dataset.compProductCollection;
      if (handle) {
        link.addEventListener('click', e => {
          e.preventDefault();
          this._updateURL(handle, collectionHandle);
          this._fetchProductByHandle(handle);
          const syncSections = document.querySelectorAll(selectors.syncSection);
          syncSections.forEach(section => {
            const syncSectionId = section.dataset.sectionId;
            if (syncSectionId) {
              this._fetchSectionByHandle(section, handle, syncSectionId);
            }
          });
        });
      }
    });
  }

  _showAllComplementaries() {
    const moreComplementaries = this.el.querySelector(
      selectors.moreComplementaries,
    );

    const complementaryLinks = this.el.querySelectorAll(
      selectors.complementaryProductTitle,
    );

    if (moreComplementaries) {
      moreComplementaries.classList.add('hidden');
      complementaryLinks.forEach(link => {
        link.classList.remove('hidden');
      });
    }
  }

  _fetchProductByHandle(handle) {
    fetch(
      `${window.Shopify.routes.root}products/${handle}?section_id=${this.sectionId}`,
    )
      .then(response => response.text())
      .then(responseText => {
        const html = responseText;
        const parsedHTML = new DOMParser().parseFromString(html, 'text/html');
        const newData = parsedHTML.querySelector('[data-section-data]');
        const newRoot = parsedHTML.querySelector(selectors.product);
        const parsedData = JSON5.parse(newData.textContent);
        this.el.innerHTML = newRoot.innerHTML;
        new Product(this.el, {}, this.sectionId, parsedData.variantMetafields);
      })
      .then(() => this._showAllComplementaries());
  }

  _fetchSectionByHandle(el, handle, sectionId) {
    fetch(
      `${window.Shopify.routes.root}products/${handle}?section_id=${sectionId}`,
    )
      .then(response => response.text())
      .then(responseText => {
        const html = responseText;
        const parsedHTML = new DOMParser().parseFromString(html, 'text/html');
        const newRoot = parsedHTML.querySelector(selectors.syncSection);
        el.innerHTML = newRoot.innerHTML;
        reRunScripts(el);
        setTimeout(() => {
          Shopify.PaymentButton.init();
        }, 1000);
      });
  }

  _updateURL(handle, collectionHandle) {
    let url = `${window.Shopify.routes.root}products/${handle}`;
    if (collectionHandle) {
      url += `?collection=${collectionHandle}`;
    }
    history.pushState({}, '', url);
  }
}
