import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../../helpers';
import { h } from 'preact';

import { useEffect, useState } from 'preact/hooks';

const CartItemPrice = ({
  item,
  final_key = 'final_line_price',
  original_key = 'original_line_price',
}) => {
  const [price, setPrice] = useState(item[final_key]);
  const [preDiscount, setPreDiscount] = useState(null);

  useEffect(() => {
    setPrice(item[final_key]);
    setPreDiscount(item[original_key]);
  }, [item]);

  return (
    <div class="flex flex-col space-x-3">
      {preDiscount > price && (
        <div class="type-body-small text-secondary-3">
          <span class="sr-only">{getTranslation('cart.original_price')}</span>
          <span class="block line-through">
            {formatMoney(preDiscount, theme.moneyFormat)}
          </span>
        </div>
      )}
      <div class="type-body-base text-secondary-1">
        {preDiscount > price && (
          <span class="sr-only">{getTranslation('cart.discounted_price')}</span>
        )}
        <span class="block">{formatMoney(price, theme.moneyFormat)}</span>
      </div>
    </div>
  );
};

export default CartItemPrice;
