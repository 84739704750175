/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './layout/**/*.liquid',
    './sections/**/*.liquid',
    './snippets/**/*.liquid',
    './templates/**/*.liquid',
    './src/scripts/**/*.{js,jsx}',
    './assets/**/*.js',
  ],
  safelist: [
    'md:col-span-1',
    'md:col-span-2',
    'md:col-span-3',
    'md:col-span-4',
    'md:col-span-5',
    'md:col-span-6',
    'md:col-span-7',
    'md:col-span-8',
    'md:col-span-9',
    'md:col-span-10',
    'md:col-span-11',
    'md:col-span-12',
    'lg:col-span-1',
    'lg:col-span-2',
    'lg:col-span-3',
    'lg:col-span-4',
    'lg:col-span-5',
    'lg:col-span-6',
    'lg:col-span-7',
    'lg:col-span-8',
    'lg:col-span-9',
    'lg:col-span-10',
    'lg:col-span-11',
    'lg:col-span-12',
  ],
  theme: {
    colors: {
      primary: {
        1: '#10312B',
        2: '#E2DDD3',
        3: '#000000',
      },
      secondary: {
        1: '#A28A7F',
        2: '#3E6983',
        3: '#9FA999',
        4: '#DA9D57',
        5: '#EBE7E0',
      },
      grey: {
        1: '#E7E9E9',
        2: '#F9F8F6',
        3: '#D6D8DA',
      },
      transparent: 'transparent',
      black: 'black',
      white: 'white',
      current: 'currentColor',
      success: '#4cc258',
      warning: '#f48e2f',
      error: '#b00020',
    },
    fontFamily: {
      sans: '"DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      heading:
        '"Argesta Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    screens: {
      sm: '576px',
      md: '820px',
      lg: '1024px',
      xl: '1366px',
    },
    extend: {
      aspectRatio: {
        'product-item': '4 / 5',
        fullscreen: '4 / 3',
      },
      spacing: {
        15: '3.75rem',
      },
      gridTemplateColumns: {
        'flexible-6': 'repeat(6, 1fr)',
        'flexible-12': 'repeat(12, 1fr)',
        13: 'repeat(13, minmax(0, 1fr))',
      },
      maxHeight: {
        112: '28rem',
        128: '32rem',
      },
      zIndex: {
        '-1': '-1',
      },
    },
  },
  plugins: [require('@tailwindcss/forms')],
};
