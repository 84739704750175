import { CountryProvinceSelector } from '@shopify/theme-addresses';
import Modal from '../components/Modal';

const selectors = {
  addressContainer: '[data-address]',
  addressToggle: '[data-address-toggle]',
  addressCountry: '[data-address-country]',
  addressProvince: '[data-address-province]',
  addressForm: '[data-address-form]',
  addressDeleteForm: '[data-address-delete-form]',
};

export default class Addresses {
  constructor() {
    this.modal = new Modal({
      onOpen: this._initCountryProvinceSelectors,
    });
    this.countryProvinceSelector = new CountryProvinceSelector(
      window.theme.allCountryOptionTags,
    );
    this.addressContainers = document.querySelectorAll(
      selectors.addressContainer,
    );

    if (this.addressContainers.length) {
      this._initForms();
    }
  }

  /**
   * Initialize forms.
   */
  _initForms() {
    this.addressContainers.forEach((container, i) => {
      const toggle = container.querySelector(selectors.addressToggle);
      const deleteButton = container.querySelector(selectors.addressDeleteForm);
      const cancelButton = document.getElementById(`cancel-${i + 1}`);
      toggle.addEventListener('click', this._openForm(container));

      if (deleteButton) {
        deleteButton.addEventListener(
          'submit',
          this._deleteButtonListener(deleteButton),
        );
      }

      if (cancelButton) {
        cancelButton.addEventListener('click', () => {
          this.modal.close();
        });
      }
    });
  }

  /**
   * Opens modal.
   *
   * @param {object} container - Container of form to be opened in the modal.
   */
  _openForm = container => event => {
    const form = container.querySelector(selectors.addressForm);
    this.modal.open(`#${form.getAttribute('id')}`);
  };

  /**
   * Listens to the delete button on each saved address row and triggers a confirmation on click.
   *
   * @param {object} button - Delete button element.
   */
  _deleteButtonListener = button => event => {
    const confirmMessage =
      window.theme.strings.customer.addresses.deleteConfirm;

    if (!window.confirm(confirmMessage)) {
      event.preventDefault();
    }
  };

  /**
   * Initializes the country and province elements after the modal has opened.
   *
   * @param {object} event - Click event.
   * @param {object} modal - Modal element that includes address form markup.
   */
  _initCountryProvinceSelectors = (event, modal) => {
    const country = modal.querySelector(selectors.addressCountry);
    const province = modal.querySelector(selectors.addressProvince);

    this.countryProvinceSelector.build(country, province, {
      onCountryChange: provinces => {
        province.classList.toggle('hidden', !provinces.length);
        province.parentNode.classList.toggle('hidden', !provinces.length);
        province.required = provinces.length;
      },
    });
  };
}
