/**
 * This function can strips the is from a string
 * @param {string} string the string to be stripped
 *
 */

const getIdfromGid = string => {
  const value = string.split('/').pop();
  return parseInt(value);
};

const mapEdgesToNodes = data => {
  if (!data?.edges) {
    return [];
  }

  return data.edges.map(edge => edge.node);
};

/**
 * This triggers an update on the react cart and cross sells
 * @param {boolean} openMiniCart
 * @param {string} crossSellItem
 */

const updateReactCart = openMiniCart => {
  const preactApp = document.querySelector('#pxu-preact-app');
  if (preactApp) {
    preactApp.dispatchEvent(
      new CustomEvent('updateReactCart', { detail: { openMiniCart } }),
    );
  }
};

export { updateReactCart, mapEdgesToNodes, getIdfromGid };
