import ProductItem from '../components/product/ProductItem';
import { initCarousel } from '../helpers/carousel';
import { unitlessBreakpoints } from '../helpers/breakpoints';

const selectors = {
  productItem: '[data-product-item]',
  collectionTab: '[data-collection-tab]',
  collectionContent: '[data-collection-content]',
  recentlyViewed: '[data-recently-viewed]',
  relatedProducts: '[data-related-products]',
  complementaryProducts: '[data-complementary-products]',
  splideList: '.splide__list',
};

const classes = {
  tabInactive: ['border-transparent', 'text-primary-1/60'],
  tabActive: ['text-primary-1', 'border-secondary-4'],
  contentInactive: 'hidden',
};

export default class DynamicProductFeed {
  constructor(section) {
    this.el = section.el;

    this.section = section;
    this.productId = section.data.productId;
    this.limit = section.data.limit || 8;
    this.productUrl = section.data.productUrl;
    this.xsCount = section.data.xsCount || 1;
    this.smCount = section.data.smCount || 2;
    this.mdCount = section.data.mdCount || 2;
    this.lgCount = section.data.lgCount || 3;
    this.xlCount = section.data.xlCount || 4;

    // Init product items
    const productItems = section.el.querySelectorAll(selectors.productItem);
    [...productItems].forEach(productItem => {
      new ProductItem(productItem);
    });

    this.initTabs();
    this.activateTab(0);

    this.addRecentlyViewedProducts();
    this.addRecommendedProducts();
    this.addComplementaryProducts();
  }

  initTabs() {
    this.collectionTabs = this.el.querySelectorAll(selectors.collectionTab);
    this.collectionContent = this.el.querySelectorAll(
      selectors.collectionContent,
    );

    this.collectionTabs.forEach((tab, index) => {
      tab.addEventListener('click', () => {
        this.deactivateAllTabs();
        this.activateTab(index);
      });
    });
  }

  initializeCarousel(index) {
    let xsGap = '0.5rem';
    let xsPadding = 10;
    let smGap = '1rem';
    let smPadding = 70;
    let mdPadding = 70;
    let lgPadding = 40;
    const xlPadding = 40;
    if (this.xsCount === 1) {
      xsGap = '1rem';
      xsPadding = 70;
    }
    if (this.smCount === 3) {
      smGap = '0.5rem';
      smPadding = 20;
    }
    if (this.mdCount === 4) {
      mdPadding = 40;
    }
    if (this.lgCount === 3) {
      lgPadding = 70;
    }

    this.carousel = initCarousel(this.collectionContent[index], {
      perPage: this.xsCount,
      pagination: false,
      gap: xsGap,
      padding: {
        right: xsPadding,
      },
      breakpoints: {
        [unitlessBreakpoints.sm]: {
          gap: smGap,
          perPage: this.smCount,
          padding: {
            right: smPadding,
          },
        },
        [unitlessBreakpoints.md]: {
          gap: '1rem',
          perPage: this.mdCount,
          padding: {
            right: mdPadding,
          },
        },
        [unitlessBreakpoints.lg]: {
          perPage: this.lgCount,
          padding: {
            right: lgPadding,
          },
        },
        [unitlessBreakpoints.xl]: {
          perPage: this.xlCount,
          padding: {
            right: xlPadding,
          },
        },
      },
    });
  }
  activateTab(index) {
    this.collectionTabs[index].classList.add(...classes.tabActive);
    this.collectionTabs[index].classList.remove(...classes.tabInactive);
    this.collectionContent[index].classList.remove(classes.contentInactive);
    this.initializeCarousel(index);
  }

  deactivateAllTabs() {
    if (this.carousel) {
      this.carousel.destroy();
    }
    this.collectionContent.forEach(content => {
      content.classList.add(classes.contentInactive);
    });
    this.collectionTabs.forEach(tab => {
      tab.classList.add(...classes.tabInactive);
      tab.classList.remove(...classes.tabActive);
    });
  }

  renderEmptyMessage(message) {
    return `<h3 class="py-24 type-heading-4 text-center">${message}</h3>`;
  }

  // Render recently viewed cards from local storage into the recently_viewed splide carousel
  addRecentlyViewedProducts() {
    const collectionContentContainer = this.el.querySelector(
      selectors.recentlyViewed,
    );

    // If doesnt have data-product-id or related-products attr on container do nothing
    if (!collectionContentContainer) {
      return;
    }

    const index = collectionContentContainer.dataset.index;
    const recentlyViewedCarousel = collectionContentContainer.querySelector(
      selectors.splideList,
    );

    const storageKey = 'hemlock-recently-viewed';
    const recentlyViewedArray =
      JSON.parse(sessionStorage.getItem(storageKey)) || {};
    const formattedRecentlyViewed = Object.values(recentlyViewedArray);

    if (recentlyViewedCarousel && formattedRecentlyViewed.length) {
      formattedRecentlyViewed.forEach(card => {
        const html = document.createElement('div');
        html.innerHTML = card;
        const newCard = html.querySelector(selectors.productItem);
        newCard.classList.add('splide__slide');

        if (newCard?.dataset?.productUrl !== this.productUrl) {
          recentlyViewedCarousel.appendChild(newCard);
        }
      });

      const products = collectionContentContainer.querySelectorAll(
        selectors.productItem,
      );

      if (products.length > 0) {
        this.initializeCarousel(index);
        products.forEach(product => new ProductItem(product));
      } else {
        collectionContentContainer.innerHTML = this.renderEmptyMessage(
          'There are no recently viewed products',
        );
      }
    }
  }

  addRecommendedProducts() {
    const collectionContentContainer = this.el.querySelector(
      selectors.relatedProducts,
    );

    // If doesnt have data-product-id or related-products attr on container do nothing
    if (!collectionContentContainer) {
      return;
    }
    this.recommendationsFetch(selectors.relatedProducts);
  }

  addComplementaryProducts() {
    const collectionContentContainer = this.el.querySelector(
      selectors.complementaryProducts,
    );
    // If doesnt have data-product-id or related-products attr on container do nothing
    if (!collectionContentContainer) {
      return;
    }

    this.recommendationsFetch(
      selectors.complementaryProducts,
      '&intent=complementary',
    );
  }

  recommendationsFetch(selector, additionalQuery = '') {
    const container = this.el.querySelector(selector);
    const index = container.dataset.index;
    const url = `${theme.routes.recommendations}?section_id=${this.section.id}&limit=${this.limit}&product_id=${this.productId}${additionalQuery}`;
    fetch(url)
      .then(response => response.text())
      .then(text => {
        const html = document.createElement('div');
        html.innerHTML = text;

        const recommendations = html.querySelector(selector);

        // If there are no splide slides for complementary products, display error
        if (
          !recommendations.querySelector('.splide__slide') &&
          additionalQuery
        ) {
          container.innerHTML = this.renderEmptyMessage(
            'There are no complementary products',
          );
          return;
        }

        if (recommendations && recommendations.innerHTML.trim().length) {
          container.innerHTML = recommendations.innerHTML;
          this.initializeCarousel(index);
          const products = container.querySelectorAll(selectors.productItem);
          products.forEach(product => new ProductItem(product));
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }
}
