const keyCodes = {
  ctrl: 17,
  control: 17,
  alt: 18,
  option: 18,
  shift: 16,
  windows: 91,
  command: 91,
  esc: 27,
  escape: 27,
  '`': 192,
  '-': 189,
  '=': 187,
  backspace: 8,
  tab: 9,
  '\\': 220,
  '[': 219,
  ']': 221,
  ';': 186,
  "'": 222,
  enter: 13,
  return: 13,
  ',': 188,
  '.': 190,
  '/': 191,
  space: 32,
  pause: 19,
  break: 19,
  insert: 45,
  delete: 46,
  home: 36,
  end: 35,
  pageup: 33,
  pagedown: 34,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  capslock: 20,
  numlock: 144,
  scrolllock: 145,
};

/**
 * Confirm which key way pressed
 * TODO: add support for event.key
 *
 * @param {object} event - the keyboard event
 * @param {string} key - the key to check for
 * @returns
 */
function isKeyCode(event, keys) {
  if (typeof keys === 'string') {
    keys = [keys];
  }

  return keys.some(key => event.keyCode === keyCodes[key]);
}

export { keyCodes, isKeyCode };
