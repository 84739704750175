import ProductAddOn from './ProductAddOn';
import { initCarousel } from '../../helpers/carousel';

const LIMIT = 4;

const selectors = {
  addOnProduct: '[data-add-on-product]',
};

/**
 * Configuration of the PDP add to cart form
 *
 * @export
 * @class ProductAddOn
 */
export default class ProductAddOnList {
  /**
   * Creates an instance of ProductAddOn.
   * @param {HTMLElement} sectionId ID of the section
   * @param {HTMLElement} productId ID of the parent product
   * @param {HTMLElement} container The container of this component
   * @param {HTMLElement} selector The selector for the container
   */
  constructor(sectionId, productId, container, selector) {
    this.el = container;
    this.limit = this.el.dataset.limit || LIMIT;
    this._recommendationsFetch(sectionId, productId, selector);
  }

  _recommendationsFetch = (sectionId, productId, selector) => {
    const url = `${theme.routes.recommendations}?section_id=${sectionId}&limit=${this.limit}&product_id=${productId}&intent=related`;
    fetch(url)
      .then(response => response.text())
      .then(text => {
        const html = document.createElement('div');
        html.innerHTML = text;

        const recommendations = html.querySelector(selector);

        if (recommendations && recommendations.innerHTML.trim().length) {
          this.el.innerHTML = recommendations.innerHTML;
          initCarousel(this.el, {
            perPage: 1,
            pagination: false,
            gap: '1rem',
          });
          const products = this.el.querySelectorAll(selectors.addOnProduct);
          products.forEach(product => new ProductAddOn(product));
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };
}
