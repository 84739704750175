import Splide from '@splidejs/splide';
import { unitlessBreakpoints } from '../../helpers';

const selectors = {
  mainCarousel: '[data-image-carousel]',
};

/**
 * Product image gallery on the PDP
 *
 * @export
 * @class ProductImages
 */
export default class ProductImages {
  constructor(el) {
    this.mainCarousel = el.querySelector(selectors.mainCarousel);
    if (this.mainCarousel) {
      this._initCarousel();
    }
  }

  /**
   * Initialize primary image carousel
   */
  _initCarousel() {
    this.mainCarousel = new Splide(this.mainCarousel, {
      perPage: 1,
      pagination: true,
      arrows: false,
      classes: {
        pagination: 'splide__pagination absolute bottom-4 inset-x-0',
      },
      mediaQuery: 'min',
      breakpoints: {
        [unitlessBreakpoints.md]: {
          destroy: true,
        },
      },
    });

    this.mainCarousel.mount();
  }
}
