import Accordion from '../components/Accordion';

export default class MainFooter {
  constructor(section) {
    this._initAccordion(section);
  }

  _initAccordion(section) {
    const el = section.el.querySelector('[data-footer-block]');

    if (!el) {
      return;
    }

    new Accordion({
      el: el,
      triggerClass: 'accordion-trigger',
      contentClass: 'accordion-content',
      closeOnClickOutside: false,
      focusOnOpen: false,
    });
  }
}
