// TODO: replace using this script with custom code
import $script from 'scriptjs';

const api = 'https://www.youtube.com/iframe_api';
let apiLoadedCallbacks = [];
let apiLoaded = false;

window.onYouTubeIframeAPIReady = () => {
  apiLoadedCallbacks.forEach(apiLoadedCallback => apiLoadedCallback());
  apiLoadedCallbacks = [];
  apiLoaded = true;
};

export default class Youtube {
  constructor({ el, videoUrl, loop }) {
    const regex =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i; // eslint-disable-line no-useless-escape

    this.el = el;
    this.id = videoUrl.match(regex)[1] || null;

    this.onApiLoaded = this._onApiLoaded.bind(this);

    this.isReady = false;
    this.onReady = this._onReady.bind(this);
    this.onReadyCallback = null;
    this.loop = loop ? 1 : 0;

    this.onStateChange = this._onStateChange.bind(this);
    this.onPlayCallback = null;

    if (apiLoaded) {
      this._onApiLoaded();
    } else {
      apiLoadedCallbacks.push(this.onApiLoaded);
      $script(api);
    }
  }

  play() {
    return new Promise(resolve => {
      this.onPlayCallback = resolve;

      if (this.isReady) {
        this.player.playVideo();
      } else {
        this.onReadyCallback = () => {
          this.player.playVideo();
        };
      }
    });
  }

  pause() {
    return new Promise(resolve => {
      this.onPlayCallback = resolve;

      if (this.isReady) {
        this.player.pauseVideo();
      } else {
        this.onReadyCallback = () => {
          this.player.pauseVideo();
        };
      }
    });
  }

  autoplay() {
    return new Promise(resolve => {
      this.onPlayCallback = resolve;

      if (this.isReady) {
        this.player.playVideo();
        this.player.mute();
      } else {
        this.onReadyCallback = () => {
          this.player.playVideo();
          this.player.mute();
        };
      }
    });
  }

  unload() {
    this.player.destroy();
  }

  _onApiLoaded() {
    const playerVars = {
      modestbranding: true,
      showinfo: false,
      controls: false,
      loop: this.loop,
      rel: 0,
    };

    if (this.loop) {
      // This is required to allow 'loop' to work based on the YouTube api
      playerVars.playlist = this.id;
    }

    this.player = new YT.Player(this.el, {
      videoId: this.id,
      playerVars,
      events: {
        onReady: this.onReady,
        onStateChange: this.onStateChange,
      },
    });
  }

  _onReady() {
    this.isReady = true;

    if (this.onReadyCallback) {
      this.onReadyCallback();
    }
  }

  _onStateChange(event) {
    const state = event.data;
    if (this.onPlayCallback && state === YT.PlayerState.BUFFERING) {
      this.onPlayCallback();
      this.onPlayCallback = null;
    }
  }
}
