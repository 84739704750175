import CartApp from './CartApp';
import { CartContextProvider } from './context/CartContext';
import { h } from 'preact';

const App = () => {
  return (
    <CartContextProvider>
      <CartApp />
    </CartContextProvider>
  );
};

export default App;
