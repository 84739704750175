import * as cartApi from '../../helpers/cartApi';
import { ProductForm as ThemeProductForm } from '@shopify/theme-product-form';
import { formatMoney } from '@shopify/theme-currency';
import { updateReactCart } from '../../preact/helpers/utilities';

const selectors = {
  form: '[data-add-product-form]',
  addOnWrapper: '[data-add-on-products]',
  addOnProduct: '[data-add-on-product]',
  productIdInput: '[data-product-id-input]',
  submitButton: '[data-add-on-cart-submit]',
  addOnImage: '[data-add-on-image]',
  swatchLink: '[data-swatch-link]',
  productLink: '[data-product-link]',
  moreSwatches: '[data-more-swatches]',
  colorOption: '[data-color-option]',
};

/**
 * Configuration of the PDP add to cart form
 *
 * @export
 * @class ProductAddOn
 */
export default class ProductAddOn {
  /**
   * Creates an instance of ProductAddOn.
   * @param {HTMLElement} productContainer The container of this product
   * @param {Object} config Settings object
   */
  constructor(productContainer) {
    this.el = productContainer;
    this._initProduct();
    this._initSwatches();
  }

  _initProduct = () => {
    this.formElement = this.el.querySelector(selectors.form);
    fetch(
      `${Shopify.routes.root}products/${this.formElement.dataset.productHandle}.js`,
    )
      .then(response => {
        return response.json();
      })
      .then(productJSON => {
        this.product = productJSON;
        this.themeProductForm = new ThemeProductForm(
          this.formElement,
          productJSON,
          {
            onOptionChange: this._onOptionChange,
            onFormSubmit: this._onFormSubmit,
          },
        );

        /**
         * On initialization, set source of truth
         */
        this._updateHiddenSelect(selectors.productIdInput);
      });
  };

  /**
   * Called any time an option is changed
   */
  _onOptionChange = event => {
    this._updateHiddenSelect(selectors.productIdInput);
  };

  /**
   * This function is called whenever the user submits the form
   *
   * @param {*} event
   */
  _onFormSubmit = event => {
    event.preventDefault();

    cartApi
      .add(event.target)
      .then(() => {
        updateReactCart(true);
      })
      .catch(response => this._handleCartApiError(response));
  };

  /**
   * Updates the dom after a variant has changed
   */
  _updateVariantState = () => {
    const variant = this.themeProductForm.variant();

    if (variant === null) {
      this._disableAddToCartState();
      return;
    }

    if (!variant.available) {
      this._soldOutAddToCartState();
    } else {
      this._enableAddToCartState(variant);
    }
  };

  /**
   * Generic error handler for cart api
   * Example: 422 response when trying to add a product whose total stock is already in cart
   *
   * @param {Object} response The response from Shopify
   */
  _handleCartApiError = response => {
    const { error } = response;
    // eslint-disable-next-line no-console
    console.error(error);
  };

  /**
   * disable button state and text
   */
  _soldOutAddToCartState = () => {
    const { soldOut } = theme.strings.product;
    const button = this.el.querySelector(selectors.submitButton);

    button.setAttribute('disabled', 'disabled');
    button.innerHTML = soldOut;
  };

  /**
   * disable button state and text
   */
  _disableAddToCartState = () => {
    const button = this.el.querySelector(selectors.submitButton);

    button.dataset.selectSize = 'true';
  };

  /**
   * enable button state and text
   */
  _enableAddToCartState = variant => {
    const { add } = theme.strings.product;
    const button = this.el.querySelector(selectors.submitButton);

    button.removeAttribute('disabled');
    button.innerHTML = `${add} - ${formatMoney(
      variant.price,
      theme.moneyFormat,
    )}`;
  };

  /**
   * Updates the hidden source of truth for the variant selected
   *
   * @param {String} selector selector of the element
   */
  _updateHiddenSelect = selector => {
    const variant = this.themeProductForm.variant();
    const variantSource = this.el.querySelector(selector);

    if (!variantSource) {
      return;
    }

    if (variant) {
      variantSource.value = variant.id;
    } else {
      variantSource.value = null;
    }

    this._updateVariantState();
  };

  _initSwatches() {
    const swatchLinks = this.el.querySelectorAll(selectors.swatchLink);

    const moreSwatches = this.el.querySelector(selectors.moreSwatches);
    if (moreSwatches) {
      moreSwatches.addEventListener('click', e => {
        e.preventDefault();
        moreSwatches.classList.add('hidden');
        swatchLinks.forEach(swatch => {
          swatch.classList.remove('hidden', 'md:hidden');
        });
      });
    }

    const featuredImage = this.el.querySelector(`${selectors.addOnImage} img`);
    const productLinks = this.el.querySelectorAll(selectors.productLink);
    const addToCartButton = this.el.querySelector(selectors.submitButton);
    const formWrapper = this.el.querySelector(selectors.form);
    const colorOption = this.el.querySelector(selectors.colorOption);

    swatchLinks.forEach(swatch => {
      swatch.addEventListener('click', () => {
        // Update add to cart ID
        if (addToCartButton) {
          addToCartButton.dataset.addToCart = swatch.dataset.swatchId;
        }

        // Remove all active state
        swatchLinks.forEach(sib => {
          sib.classList.remove('active-swatch');
        });
        // Add active state to selected swatch
        swatch.classList.add('active-swatch');

        // Update product card links
        productLinks.forEach(link => {
          link.setAttribute('href', swatch.dataset.swatchUrl);
        });

        formWrapper.dataset.productHandle = swatch.dataset.swatchHandle;
        colorOption.value = swatch.dataset.swatchColor;

        const swatchImageOne = swatch.dataset.swatchImageOne;
        featuredImage.setAttribute('src', swatchImageOne);
        featuredImage.removeAttribute('srcset');
        featuredImage.removeAttribute('sizes');

        this.themeProductForm.destroy();
        this._initProduct();
      });
    });
  }
}
