import JSON5 from 'json5';

/**
 * Injects HTML
 * @param {Array} elements - Array of objects
 *
 * object params
 * @param {String} selector - Target selector of element e.g .class | #id
 * @param {String} id - ID to set to the injectedHTML div
 * @param {String} class - class to set to the injectedHTML div
 * @param {String} html - HTML to set inside the injectedHTML div
 * @param {string} position - Insert HTML position the targeted selector, on default appends HTML inside the selector, after its last child
 * position values beforebegin, afterbegin, beforeend, afterend
 */

/**
 * Example of object
   {
    selector: '.product-details__description',
    id: 'test-id',
    class: 'test-class',
    html: `<p>Inserted new HTML!</p>`,
    position: 'beforeend',
  }
*/

const injectedHTML = elements => {
  elements.forEach(element => {
    // If selector is not specified or element doesnt exist do nothing.
    if (!element.selector || !document.querySelector(`${element.selector}`)) {
      return;
    }

    // Create markup of the HTML
    const html = `
    <div
      ${element.class && `class="${element.class}"`}
      ${element.id && `id="${element.id}"`}
    >
      ${element.html}
    </div>`;

    // Insert HTML
    document
      .querySelector(`${element.selector}`)
      .insertAdjacentHTML(
        element.position ? element.position : 'beforeend',
        html,
      );
  });
};

/**
 * Helper method to return product sizes as abbreviations, e.g Medium => M, Extra Large => XL etc...
 * @param {Object} values array of size values
 */
const returnAbbreviatedSizes = values => {
  const sizes = [
    'EXTRA EXTRA SMALL',
    'EXTRA SMALL',
    'SMALL',
    'MEDIUM',
    'LARGE',
    'EXTRA LARGE',
    'EXTRA EXTRA LARGE',
    'EXTRA EXTRA EXTRA LARGE',
  ];

  const mappedSizes = values.map(value => {
    /**
     * If value contains n amount of x's returns XS/XL/XXL etc.
     */
    if (value.includes('x')) {
      const xAmount = 'X'.repeat(value.split('x').length - 1);
      const sizeValueIndex = value.split(' ').length - 1;
      return `${xAmount}${value.split(' ')[sizeValueIndex][0].toUpperCase()}`;
    }

    /**
     * If value exists in sizes array, return first letter of size uppercase, else return full value
     */
    return sizes.includes(value.toUpperCase())
      ? value[0].toUpperCase()
      : `${value}`;
  });

  return mappedSizes;
};

/**
 * Returns selected variants metafield as an object
.*/
const getVariantMetafields = variant => {
  const dataScript = document.querySelector(
    '[data-section-type="main-product"]',
  );

  if (!dataScript) {
    return;
  }

  const variantInformation = JSON5.parse(dataScript.innerHTML)
    .variantMetafields[variant.id];

  return variantInformation;
};

/**
 * When replacing innerHTML of an element, script tags are not executed. This function reruns the script tags.
 */
const reRunScripts = element => {
  Array.from(element.querySelectorAll('script')).forEach(oldScriptEl => {
    const newScriptEl = document.createElement('script');

    Array.from(oldScriptEl.attributes).forEach(attr => {
      newScriptEl.setAttribute(attr.name, attr.value);
    });

    const scriptText = document.createTextNode(oldScriptEl.innerHTML);
    newScriptEl.appendChild(scriptText);

    oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
  });
};

export {
  injectedHTML,
  returnAbbreviatedSizes,
  getVariantMetafields,
  reRunScripts,
};
