import VanillaModal from 'vanilla-modal';
import focusTrap from 'focus-trap';
import scrollLock from 'scroll-lock';

const selectors = {
  modal: '[data-pxu-modal]',
  modalInner: '[data-pxu-modal-inner]',
  modalContent: '[data-pxu-modal-content]',
};

/**
 * Wrapper class for modal dialogs.
 * A modal can be created in markup using a link to a hidden element
 * (https://www.npmjs.com/package/vanilla-modal#3-create-the-modals-container-using-html)
 * or programatically, using the api
 * (https://www.npmjs.com/package/vanilla-modal#7-programmatically-opening-a-modal)
 *
 * @export
 * @class Modal
 */
export default class Modal {
  constructor(config = { size: 'large' }) {
    this.modal = null;
    this.modalEl = document.querySelector(selectors.modal);
    this.modalInner = this.modalEl.querySelector(selectors.modalInner);

    // Extend default vanilla-modal callbacks back to instantiator of Modal
    // so that we can add additional functionality for a11y etc.
    this.config = Object.assign(
      {
        onBeforeOpen: event => {},
        onOpen: (event, modal) => {},
        onClose: (event, modal) => {},
        onBeforeClose: event => {},
      },
      config,
    );

    switch (this.config.size) {
      case 'small':
        this.sizeClass = 'max-w-md';
        break;
      case 'medium':
        this.sizeClass = 'max-w-xl';
        break;
      default:
        this.sizeClass = 'max-w-full';
        break;
    }

    this._init();
  }

  /**
   * Create modal instance and focusTrap
   */
  _init() {
    this.modal = new VanillaModal({
      close: '[data-pxu-modal-close]',
      modal: selectors.modal,
      modalInner: selectors.modalInner,
      modalContent: selectors.modalContent,
      page: selectors.modal,
      loadClass: '',
      class: '',
      clickOutside: true,
      onOpen: this._onOpen,
      onClose: this._onClose,
      onBeforeOpen: this._onBeforeOpen,
      onBeforeClose: this._onBeforeClose,
    });

    this.focusTrap = focusTrap(selectors.modalInner, {
      onActivate: () => {
        this.modalEl.classList.add('focus-trap');
      },
      onDeactivate: () => {
        this.modalEl.classList.remove('focus-trap');
      },
      initialFocus: () => document.querySelector(selectors.modalInner),
      clickOutsideDeactivates: true,
    });
  }

  /**
   * Callback that fires before modal opens
   *
   * @param {Object} event
   */
  _onBeforeOpen = event => {
    this.config.onBeforeOpen(event);
    this.modalInner.classList.add(this.sizeClass);
  };

  /**
   * Callback that fires when modal opens
   *
   * @param {Object} event
   */
  _onOpen = event => {
    this.modalEl.setAttribute('aria-hidden', false);
    this.modalEl.classList.remove('opacity-0', 'invisible');
    this.modalEl.classList.add('visible', 'opacity-100');
    this.focusTrap.activate();
    scrollLock.disablePageScroll();
    this.config.onOpen(event, this.modalEl);
  };

  /**
   * Callback that fires before modal closes
   *
   * @param {Object} event
   */
  _onBeforeClose = event => {
    this.config.onBeforeClose(event);
  };

  /**
   * Callback that fires when modal closes
   *
   * @param {Object} event
   */
  _onClose = event => {
    this.modalEl.setAttribute('aria-hidden', true);
    this.modalEl.classList.remove('visible', 'opacity-100');
    this.modalEl.classList.add('opacity-0', 'invisible');
    this.focusTrap.deactivate();
    scrollLock.enablePageScroll();
    this.config.onClose(event, this.modalEl);
    this.modalInner.classList.remove(this.sizeClass);
  };

  /**
   * Test if a modal is open
   *
   * @returns {boolean}
   */
  isOpen() {
    return this.modal && this.modal.isOpen;
  }

  /**
   * Open a modal with contents from selector
   *
   * @param selector The contents to inject
   */
  open(selector) {
    if (this.modal && this.modal.isOpen) {
      return;
    }

    this.modal.open(selector);
  }

  /**
   * Close a modal
   */
  close() {
    this.modal.close();
  }

  /**
   * Garbage collection
   */
  unload() {
    if (!this.modal || !this.modal.isOpen) {
      return;
    }

    this.modal.destroy();
  }
}
