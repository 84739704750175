import Splide from '@splidejs/splide';

const selectors = {
  mainCarousel: '[data-splide-carousel]',
  currentNumber: '[data-splide-current-number]',
};

const updatePageNumber = slider => {
  function mount() {
    update(slider.index);
    slider.on('move', newIndex => {
      update(newIndex);
    });
  }

  function update(newIndex) {
    const currentNumber = slider.root.querySelector(selectors.currentNumber);

    if (currentNumber) {
      const displayNumber = String(newIndex + 1);
      currentNumber.innerHTML = displayNumber;
    }
  }

  return {
    mount,
  };
};

const hideArrows = slider => {
  slider.on('overflow', isOverflow => {
    slider.options = {
      arrows: isOverflow,
      drag: isOverflow,
    };
  });
};

const initCarousel = (el, options = {}) => {
  const carousel = el.querySelector(selectors.mainCarousel);

  if (carousel) {
    const carouselOptions = {
      perPage: 2,
      perMove: 1,
      speed: 1000,
      flickPower: 100,
      flickMaxPower: 0.3,
      mediaQuery: 'min',
      pagination: false,
      ...options,
    };

    const splide = new Splide(carousel, carouselOptions);
    hideArrows(splide);
    splide.mount({
      updatePageNumber,
    });

    return splide;
  }
};

export { initCarousel, updatePageNumber };
