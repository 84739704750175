import CartCrossSell from './CartCrossSell';
import CartEmpty from './CartEmpty';
import CartItem from './CartItem';
import CartTotals from './CartTotals';
import { createPortal } from 'preact/compat';
import { useCartContext } from '../context/CartContext';
import useEmptyPortal from '../hooks/useEmptyPortal';

import { Fragment, h } from 'preact';

const CartPageEl = document.getElementById('preact-full-cart');

const CartPage = () => {
  const { cart } = useCartContext();
  const [emptied] = useEmptyPortal(CartPageEl);

  if (!emptied) {
    return null;
  }

  return createPortal(
    <Fragment>
      {cart.items && cart.items.length > 0 ? (
        cart.items.map(item => <CartItem key={item.id} item={item} />)
      ) : (
        <CartEmpty />
      )}

      <CartCrossSell />

      {cart.total_price > 0 && <CartTotals cart={{ ...cart }} />}
    </Fragment>,
    CartPageEl,
  );
};

export default CartPage;
