import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const CartEmpty = () => {
  const { miniCartData } = useCartContext();

  return (
    <div
      class="rte type-heading-4 py-20 text-center"
      dangerouslySetInnerHTML={{
        __html: miniCartData.settings.emptyCartMessage,
      }}
    ></div>
  );
};

export default CartEmpty;
