import MiniCartDrawer from './MiniCartDrawer';
import MiniCartTrigger from './MiniCartTrigger';
import scrollLock from 'scroll-lock';
import { useCartContext } from '../context/CartContext';
import { useEffect } from 'preact/hooks';
import { Fragment, h } from 'preact';

const MiniCart = () => {
  const { isMiniCartOpen } = useCartContext();

  useEffect(() => {
    if (isMiniCartOpen) {
      scrollLock.disablePageScroll();
    } else {
      scrollLock.enablePageScroll();
    }
  }, [isMiniCartOpen]);

  return (
    <Fragment>
      <MiniCartTrigger />
      <MiniCartDrawer />
    </Fragment>
  );
};

export default MiniCart;
