import Accordion from '../components/Accordion';

export default class DynamicAccordion {
  constructor(section) {
    this._initAccordion(section);
  }

  _initAccordion(section) {
    const el = section.el.querySelector('[data-accordion-block]');

    if (el) {
      new Accordion({
        el: el,
        triggerClass: 'accordion-trigger',
        contentClass: 'accordion-content',
        closeOnClickOutside: false,
        focusOnOpen: false,
      });
    }
  }
}
