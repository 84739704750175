import Product from '../components/product/Product';

export default class DynamicFeaturedProduct {
  constructor(section) {
    const product = section.el.querySelector('[data-product]');

    new Product(product, {
      isFeaturedProduct: true,
    });
  }
}
