import { default as SiteLocaleEn } from '../../../locales/en.default.json';

/**
 * This function can be used for locale translation in javascript files
 *
 * @param {string} string the translation string to be used. eg: 'product.sale'
 * @param {object} replacements an object with each key being the string to be replaced and the value being what to replace it with.
 */
const getTranslation = (string, replacements) => {
  const languages = {
    en: SiteLocaleEn,
  };

  let translation = string
    .split('.')
    .reduce((acc, cur) => acc[cur], languages[Shopify.locale]);

  if (replacements) {
    Object.entries(replacements).forEach(([key, val]) => {
      translation = translation.replaceAll(`{{ ${key} }}`, val);
    });
  }

  return translation;
};

export default getTranslation;
