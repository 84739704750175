import Product from '../components/product/Product';

export default class MainProduct {
  constructor(section) {
    const product = section.el.querySelector('[data-product]');
    const data = section.data;

    if (product) {
      new Product(product, {}, section.id, data.variantMetafields);
      this.trackRecentlyViewed(data);
    }
  }

  // Utility function to store recently viewed product handle into local storage
  trackRecentlyViewed = data => {
    if (data?.trackRecentlyViewed) {
      const storageKey = 'hemlock-recently-viewed';
      // Retrieve recentlyViewed from local storage
      const recentlyViewedArray = sessionStorage.getItem(storageKey);

      // Parse the retrieved data into an array
      const recentlyViewedProducts = JSON.parse(recentlyViewedArray) || {};
      if (!recentlyViewedProducts[data.productHandle]) {
        recentlyViewedProducts[data.productHandle] = data.productCard;
        sessionStorage.setItem(
          storageKey,
          JSON.stringify(recentlyViewedProducts),
        );
      }
    }
  };
}
