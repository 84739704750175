import CartItemPrice from './CartItemPrice';
import CartRemove from './CartRemove';
import QuantitySelector from '../shared-components/QuantitySelector';
import cn from 'classnames';
import { getSizedImageUrl } from '@shopify/theme-images';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useState } from 'preact/hooks';

const CartItem = ({ item }) => {
  const [error, setError] = useState();

  return (
    <div class="relative flex border-b border-primary-1/10 p-4">
      {item.featured_image && item.featured_image.url && (
        <div class="mr-3 w-20 sm:w-24">
          <a class="block aspect-product-item" href={item.url}>
            <img
              class="h-full w-full object-cover "
              alt={item.featured_image.alt}
              src={getSizedImageUrl(item.featured_image.url, 'medium')}
            />
          </a>
        </div>
      )}

      <div class="flex-1">
        <div class="flex h-full flex-col">
          <div class="flex-1">
            <a class="type-body-large block" href={item.url}>
              {item.altProductTitle
                ? item.altProductTitle.value
                : item.product_title}
            </a>
            <p className="type-body-small text-primary-1/70">
              {!item.product_has_only_default_variant &&
                item.options_with_values.map(
                  ({ name, value }, i) =>
                    name === 'Size' && (
                      <span
                        className={cn({
                          "after:mx-2 after:content-['·']":
                            item.options_with_values.length - 1 > i,
                        })}
                      >
                        {value}
                      </span>
                    ),
                )}
            </p>
            <div class="flex">
              <CartItemPrice item={item} />
            </div>
            {item.selling_plan_allocation && (
              <span class="type-text-8 bg-grey-4 my-1 inline-flex items-center px-2 py-1">
                <svg
                  className="mr-1 w-5"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.3 3.3v4.2h.5m0 0a6.7 6.7 0 0 1 12.8 1.7M3.8 7.5h3.7m9.2 9.2v-4.2h-.5m0 0a6.7 6.7 0 0 1-12.8-1.7m12.8 1.7h-3.7"
                    stroke="currentColor"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
                {item.selling_plan_allocation.selling_plan.name}
              </span>
            )}

            {item.properties &&
              Object.entries(item.properties).map(
                ([key, val]) =>
                  val &&
                  !key.startsWith('_') && (
                    <p className="type-text-8">
                      {key}:{' '}
                      {val.includes('/uploads/') ? (
                        <a href={val}>{val.split('/').pop()}</a>
                      ) : (
                        val
                      )}
                    </p>
                  ),
              )}
          </div>

          <CartRemove className="mt-auto text-left leading-none" item={item} />

          <div class="absolute bottom-4 right-4 flex flex-col">
            <QuantitySelector item={item} setError={setError} />
          </div>
        </div>

        {/* Discounts */}
        {item.line_level_discount_allocations.length > 0 && (
          <div class="mt-2 space-y-2">
            {Object.entries(item.line_level_discount_allocations).map(
              ([key, value]) => (
                <div key={key} class="type-text-8">
                  {value.discount_application.title}
                </div>
              ),
            )}
          </div>
        )}
      </div>

      {error && (
        <div class="type-text-8 absolute bottom-1 right-0 text-error">
          {getTranslation('cart.quantity_error', { quantity: error })}
        </div>
      )}
    </div>
  );
};

export default CartItem;
