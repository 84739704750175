import DynamicAccordion from '../scripts/sections/DynamicAccordion';
import DynamicCardFeed from '../scripts/sections/DynamicCardFeed';
import DynamicFeaturedProduct from '../scripts/sections/DynamicFeaturedProduct';
import DynamicFeaturedTestimonials from '../scripts/sections/DynamicFeaturedTestimonials';
import DynamicImageGallery from '../scripts/sections/DynamicImageGallery';
import DynamicMediaWithText from '../scripts/sections/DynamicMediaWithText';
import DynamicProductFeed from '../scripts/sections/DynamicProductFeed';
import DynamicVideo from '../scripts/sections/DynamicVideo';

// Sections
import MainAddresses from '../scripts/sections/MainAddresses';
import MainCollection from '../scripts/sections/MainCollection';
import MainFaq from '../scripts/sections/MainFaq';
import MainFooter from '../scripts/sections/MainFooter';
import MainHeader from '../scripts/sections/MainHeader';
import MainProduct from '../scripts/sections/MainProduct';
import Sections from '../scripts/helpers/SectionManager';

// React
import mountPreact from '../scripts/preact/mountPreact';

// Init global sections
const sections = new Sections();
sections.register('main-product', section => new MainProduct(section));
sections.register('main-addresses', section => new MainAddresses(section));
sections.register(
  'main-collection-grid',
  section => new MainCollection(section),
);
sections.register('main-header', section => new MainHeader(section));
sections.register('main-faq', section => new MainFaq(section));
sections.register('main-footer', section => new MainFooter(section), {
  lazy: true,
});

sections.register(
  'dynamic-card-feed',
  section => new DynamicCardFeed(section),
  { lazy: true },
);

sections.register(
  'dynamic-accordion',
  section => new DynamicAccordion(section),
  {
    lazy: true,
  },
);

sections.register(
  'dynamic-product-feed',
  section => new DynamicProductFeed(section),
  { lazy: true },
);
sections.register(
  'dynamic-featured-testimonials',
  section => new DynamicFeaturedTestimonials(section),
  { lazy: true },
);
sections.register(
  'dynamic-featured-product',
  section => new DynamicFeaturedProduct(section),
  {
    lazy: true,
  },
);
sections.register(
  'dynamic-media-w-text',
  section => new DynamicMediaWithText(section),
  {
    lazy: true,
  },
);
sections.register(
  'dynamic-image-gallery',
  section => new DynamicImageGallery(section),
  {
    lazy: true,
  },
);
sections.register('dynamic-video', section => new DynamicVideo(section), {
  lazy: true,
});

sections.register('main-search', section => new MainCollection(section));

// Init preact
mountPreact();
