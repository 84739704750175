import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const CartTotals = () => {
  const {
    cart: { cart_level_discount_applications, total_price },
  } = useCartContext();

  return (
    <div>
      <div class="mt-5 flex justify-between">
        <p class="type-heading-4">{getTranslation('cart.subtotal')}</p>
        <p class="type-heading-5">
          {formatMoney(total_price, theme.moneyFormat)}
        </p>
      </div>

      {/* Discounts */}
      {cart_level_discount_applications.length > 0 && (
        <div class="mt-4 flex flex-col items-end space-y-2">
          {cart_level_discount_applications.map(item => (
            <div class="type-body-small">
              {item.title} (-
              {formatMoney(item.total_allocated_amount, theme.moneyFormat)})
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CartTotals;
