import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from 'tailwind.config.js'; // IMPORTANT that the path is NOT relative, only the file name

const config = resolveConfig(tailwindConfig);

const unitlessBreakpoints = {};
Object.entries(config.theme.screens).forEach(([key, val]) => {
  unitlessBreakpoints[key] = val.replace('px', '');
});

const pixelBreakpoints = config.theme.screens;

export { pixelBreakpoints, unitlessBreakpoints };
