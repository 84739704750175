import Modal from '../../components/Modal';
import ProductAddOnList from './ProductAddOnList';
import ProductAsyncSwitcher from './ProductAsyncSwitcher';
import ProductForm from './ProductForm';
import ProductImages from './ProductImages';
import { fetchCollectionDetailsByHandle } from '../../helpers/storefront';
import { formatMoney } from '@shopify/theme-currency';

const selectors = {
  priceWrapper: '[data-price-wrapper]',
  productPrice: '[data-product-price]',
  comparePrice: '[data-compare-price]',
  comparePriceText: '[data-compare-text]',
  comparePriceText: '[data-compare-text]',
  complementaryProductLabel: '[data-comp-product-label]',
  complementaryProductTitle: '[data-comp-product-title]',
  complementaryProductLink: '[data-comp-product-link]',
  moreComplementaries: '[data-more-complementary]',
  sizeContent: '[data-pdp-size-content]',
  sizeChartOpenButton: '[data-open-size-guide]',
  addOnWrapper: '[data-add-on-products]',
  breadcrumb: '[data-breadcrumbs]',
};

const cssClasses = {
  hidden: 'hidden',
};

/**
 * The wrapper class for PDP components
 *
 * @export
 * @class Product
 */
export default class Product {
  /**
   * Creates an instance of Product.
   *
   * @param {HTMLElement} el The container of this product
   * @param {Object} config Settings object
   * @param {String} config Section ID
   */
  constructor(el, config = {}, sectionId, metafields) {
    this.el = el;
    this.sectionId = sectionId;
    this.productId = el.dataset.productId;
    this.config = Object.assign(
      { isQuickShop: false, isFeaturedProduct: false },
      config,
    );
    this.metafields = metafields;
    this.modal = new Modal();

    this._initComponents();

    const sizeChartOpenButton = el.querySelector(selectors.sizeChartOpenButton);
    if (sizeChartOpenButton) {
      sizeChartOpenButton.addEventListener('click', this._openSizeModal(el));
    }
  }

  /**
   * Opens drawer.
   *
   * @param {object} container - Container of form to be opened in the drawer.
   */
  _openSizeModal = container => event => {
    const modal = container.querySelector(selectors.sizeContent);
    this.modal.open(`#${modal.getAttribute('id')}`);
  };

  /**
   * Init all components that make up the product
   */
  _initComponents() {
    const urlParams = new URLSearchParams(window.location.search);
    const collectionHandle = urlParams.get('collection');
    new ProductImages(this.el);
    this.productForm = new ProductForm(this.el, {
      onVariantChange: variant => this._onVariantChange(variant),
      isQuickShop: this.config.isQuickShop,
      isFeaturedProduct: this.config.isFeaturedProduct,
    });
    this._complementaryHoverLabel();
    this._loadMoreComplementaries();
    this._insertCollectionBreadcrumb(collectionHandle);
    this._appendCollectionHandleComplementaries(collectionHandle);
    const addOnWrapper = this.el.querySelector(selectors.addOnWrapper);

    if (addOnWrapper) {
      new ProductAddOnList(
        this.sectionId,
        this.productId,
        addOnWrapper,
        selectors.addOnWrapper,
      );
    }

    if (!this.config.isQuickShop) {
      new ProductAsyncSwitcher(this.el, this.sectionId);
    }
  }

  _loadMoreComplementaries = () => {
    const moreComplementaries = this.el.querySelector(
      selectors.moreComplementaries,
    );
    const complementaryLinks = this.el.querySelectorAll(
      selectors.complementaryProductTitle,
    );

    if (moreComplementaries) {
      moreComplementaries.addEventListener('click', () => {
        moreComplementaries.classList.add('hidden');
        complementaryLinks.forEach(link => {
          link.classList.remove('hidden');
        });
      });
    }
  };

  /**
   * Call functions to update on variant change from product form
   *
   * @param {Object} variant
   */
  _complementaryHoverLabel = () => {
    const complementaryProductLabel = this.el.querySelector(
      selectors.complementaryProductLabel,
    );
    const complementaryProductTitles = this.el.querySelectorAll(
      selectors.complementaryProductTitle,
    );

    if (complementaryProductLabel && complementaryProductTitles) {
      const initialTitle = complementaryProductLabel.innerHTML;
      complementaryProductTitles.forEach(title => {
        const compTitle = title.dataset.compProductTitle;

        title.addEventListener('mouseenter', () => {
          complementaryProductLabel.innerHTML = compTitle;
        });
        title.addEventListener('mouseleave', () => {
          complementaryProductLabel.innerHTML = initialTitle;
        });
      });
    }
  };

  _insertCollectionBreadcrumb = collectionHandle => {
    const breadcrumb = document.querySelector(selectors.breadcrumb);
    if (!breadcrumb || !collectionHandle) {
      return;
    }
    fetchCollectionDetailsByHandle(collectionHandle).then(response => {
      const title = response.data.collection.title;
      const arrow = document.createElement('span');
      arrow.setAttribute('aria-hidden', 'true');
      arrow.innerHTML = '›\n';

      const collectionLink = document.createElement('a');
      collectionLink.href = `/collections/${collectionHandle}`;
      collectionLink.innerHTML = title + '\n';
      collectionLink.title = title;

      const lastChild = breadcrumb.lastElementChild;
      breadcrumb.insertBefore(collectionLink, lastChild);
      breadcrumb.insertBefore(arrow, lastChild);
    });
  };

  _appendCollectionHandleComplementaries(collectionHandle) {
    if (!collectionHandle) {
      return;
    }

    const complementaryProductLinks = this.el.querySelectorAll(
      selectors.complementaryProductLink,
    );

    complementaryProductLinks.forEach(link => {
      link.dataset.compProductCollection = collectionHandle;
    });
  }

  /**
   * Call functions to update on variant change from product form
   *
   * @param {Object} variant
   */
  _onVariantChange = variant => {
    this._updatePrice(variant);
    this._updateSelectedOptionTitle(variant);
  };

  /**
   * Update prices when selected variant changes
   *
   * @param {Object} variant
   */
  _updatePrice = variant => {
    const comparePrice = this.el.querySelectorAll(selectors.comparePrice);
    const compareText = this.el.querySelectorAll(selectors.comparePriceText);
    const mainPrice = this.el.querySelectorAll(selectors.productPrice);

    const isOnSale = variant.compare_at_price > variant.price;
    const comparePriceText = isOnSale
      ? formatMoney(variant.compare_at_price, theme.moneyFormat)
      : '';

    // Update the product price
    mainPrice.forEach(price => {
      price.innerHTML = formatMoney(variant.price, theme.moneyFormat);
    });

    // Update compare at price depending on whether variant is on sale
    comparePrice.forEach((price, index) => {
      price.innerHTML = comparePriceText;
      if (isOnSale) {
        price.classList.remove(cssClasses.hidden);
        compareText[index].classList.remove(cssClasses.hidden);
      } else {
        price.classList.add(cssClasses.hidden);
        compareText[index].classList.add(cssClasses.hidden);
      }
    });
  };

  _updateSelectedOptionTitle = variant => {
    const option1Span = document.querySelector(
      '[data-current-option="option1"]',
    );
    const option2Span = document.querySelector(
      '[data-current-option="option2"]',
    );
    const option3Span = document.querySelector(
      '[data-current-option="option3"]',
    );

    if (option1Span) {
      option1Span.innerHTML = variant.option1;
    }
    if (option2Span) {
      const measurement = this.metafields[variant.id]?.measurement;
      if (measurement) {
        option2Span.innerHTML = measurement;
      } else {
        option2Span.innerHTML = variant.option2;
      }
    }
    if (option3Span) {
      option3Span.innerHTML = variant.option3;
    }
  };
}
