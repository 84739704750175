const shopifyAccessToken = '834e63cfb0c3897b995ce234b0c16041';
const shopifyShopName = 'hemlock-hat-co';
const shopifyUrl = `https://${shopifyShopName}.myshopify.com/api/2024-01/graphql.json`;

const makeShopifyRequest = params => {
  const reqOptions = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': Shopify.locale,
      'X-Shopify-Storefront-Access-Token': shopifyAccessToken,
    },
    body: JSON.stringify(params),
  };

  return fetch(shopifyUrl, reqOptions)
    .then(res => res.json())
    .then(response => {
      return response;
    });
};

const fetchAddOnProductsByHandle = handle => {
  const params = {
    query: `query($handle: String!) {
      product(handle: $handle) {
        ...on Product {
          id
          handle
          altProductTitle: metafield(namespace: "hemlock", key: "alt_product_title") {
            value
          }
          productGroup: metafield(namespace: "hemlock", key: "product_group") {
            reference {
              ...on Metaobject {
                hideSwatches: field (key: "hide_upsell_swatches") {
                  value
                }
                productList: field (key: "product_list") {
                  references(first: 99) {
                    nodes {
                      ...on Product {
                        id
                        handle
                        variants(first: 99) {
                          nodes {
                            id
                            availableForSale
                            selectedOptions {
                              name
                              value
                            }
                          }
                        }
                        options {
                          id
                          name
                          values
                        }
                        featuredImage {
                          url
                        }
                        swatchImage: metafield(namespace: "hemlock", key: "swatch_image") {
                          id
                          reference {
                            ... on MediaImage {
                              image {
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,
    variables: {
      handle,
    },
  };

  return makeShopifyRequest(params);
};

const fetchCartItemsProductsByHandle = ids => {
  const params = {
    query: `query($ids: String) {
      products(first: 50, query: $ids) {
        nodes {
          ...on Product {
            handle
            altProductTitle: metafield(namespace: "hemlock", key: "alt_product_title") {
              value
            }
          }
        }
      }
    }`,
    variables: {
      ids,
    },
  };

  return makeShopifyRequest(params);
};

const fetchCollectionDetailsByHandle = handle => {
  const params = {
    query: `query($handle: String!) {
      collection(handle: $handle) {
        ...on Collection {
          handle
          title
        }
      }
    }`,
    variables: {
      handle,
    },
  };

  return makeShopifyRequest(params);
};

export {
  fetchAddOnProductsByHandle,
  fetchCartItemsProductsByHandle,
  fetchCollectionDetailsByHandle,
};
