import { initCarousel } from '../helpers/carousel';
import { unitlessBreakpoints } from '../helpers/breakpoints';

export default class DynamicCardFeed {
  constructor(section) {
    const xsWidth = section.data.xsWidth || 220;
    const smWidth = section.data.smWidth || 240;
    const mdWidth = section.data.mdWidth || 260;
    const lgWidth = section.data.lgWidth || 290;

    initCarousel(section.el, {
      perPage: 1,
      fixedWidth: xsWidth,
      pagination: false,
      breakpoints: {
        [unitlessBreakpoints.sm]: {
          fixedWidth: smWidth,
        },
        [unitlessBreakpoints.md]: {
          fixedWidth: mdWidth,
        },
        [unitlessBreakpoints.lg]: {
          fixedWidth: lgWidth,
        },
      },
    });
  }
}
