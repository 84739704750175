import App from './App';

import { h, render } from 'preact';

const mountPreact = () => {
  const appEl = document.getElementById('pxu-preact-app');

  if (appEl) {
    render(<App />, appEl);
  }
};

export default mountPreact;
