import CartPage from './cart/CartPage';
import MiniCart from './cart/MiniCart';
import { useCartContext } from './context/CartContext';

import { Fragment, h } from 'preact';

const CartApp = () => {
  const { cart } = useCartContext();

  if (!cart) {
    return null;
  }

  return (
    <Fragment>
      <MiniCart />
      <CartPage />
    </Fragment>
  );
};

export default CartApp;
