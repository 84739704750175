import Facets from '../components/Facets';
import MobileFilterDrawer from '../components/MobileFilterDrawer';
import ProductItem from '../components/product/ProductItem';

const selectors = {
  productItems: '[data-product-item]',
  filterForm: '[data-filter-form]',
};

export default class MainCollection {
  constructor(section) {
    const productItems = section.el.querySelectorAll(selectors.productItems);

    [...productItems].forEach(productItem => {
      new ProductItem(productItem);
    });

    Facets();
    new MobileFilterDrawer();
  }
}
