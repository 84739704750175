const defaultDebounceTimeMs = 100;

/**
 * Debounce a function
 * @param {Function} func - The function to call after debounce period
 * @param {Number} wait - Time to wait before function calls
 * @param {Boolean} immediate - Call the function immediately
 * @returns {Function}
 */
const debounce = (func, wait = defaultDebounceTimeMs, immediate) => {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;

    const later = () => {
      timeout = null;

      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

export default debounce;
