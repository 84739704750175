const selectors = {
  input: '[data-quantity-input]',
  increment: '[data-quantity-increment]',
  decrement: '[data-quantity-decrement]',
};

/**
 * Controller for a quantity input
 *
 * @export
 * @class Quantity
 */
export default class Quantity {
  /**
   * Creates an instance of Quantity.
   * @param {HTMLElement} el Container element / scope
   * @param {Object} [config={}] Config object for callbacks and settings
   */
  constructor(el, config = {}) {
    this.el = el;
    this.input = this.el.querySelector(selectors.input);
    this.incrementButton = this.el.querySelector(selectors.increment);
    this.decrementButton = this.el.querySelector(selectors.decrement);

    // If input doesnt exist, return
    if (!this.el.querySelector(selectors.input)) {
      return;
    }

    this.config = Object.assign(
      {
        onChange: () => {},
        onError: () => {},
      },
      config,
    );

    this.min = this.input.getAttribute('min')
      ? parseInt(this.input.getAttribute('min'), 10)
      : 0;
    this.max = this.input.getAttribute('max')
      ? parseInt(this.input.getAttribute('max'), 10)
      : Infinity;

    this._onInputChange = this._onInputChange.bind(this);
    this._adjustInput = this._adjustInput.bind(this);

    this._bindEvents();
  }

  /**
   * Attach event handlers
   */
  _bindEvents() {
    this.decrementButton.addEventListener('click', () => {
      this._adjustInput(-1);
    });
    this.incrementButton.addEventListener('click', () => {
      this._adjustInput(+1);
    });
    this.input.addEventListener('change', this._onInputChange);
  }

  /**
   * Handler for the input change event
   *
   * @param {object} event
   */
  _onInputChange(event) {
    const quantity = this._setValue(this.input.value);

    this.config.onChange(event, quantity);
  }

  /**
   * Make sure that value is a number
   *
   * @param {number|string} value
   * @returns {number}
   */
  _formatValue(value) {
    const baseValue = parseInt(value, 10);
    return isNaN(baseValue) ? this.min : baseValue;
  }

  /**
   * Increment or decrement input based on adjustment
   *
   * @param {number} adjustment
   */
  _adjustInput(adjustment) {
    if (this.input.disabled) {
      return;
    }

    const value = this._formatValue(this.input.value) + adjustment;

    if (value > this.max) {
      this.config.onError({
        min: this.min,
        max: this.max,
      });

      return;
    }

    this.input.value = value;
    this.input.dispatchEvent(new Event('change'));
  }

  /**
   * Restrict input to be within a valid number range
   *
   * @param {number} value
   * @returns {number}
   */
  _restrictValue(value) {
    let val = Math.min(this.max, value); // Make sure value isn't larger than maximum
    val = Math.max(this.min, val); // Make sure value isn't lower than minimum

    return val;
  }

  /**
   * Set the value of the input to a sanitized state
   *
   * @param {number|string} value
   * @returns {number}
   */
  _setValue(value) {
    const formatted = this._formatValue(value);
    const constrainedValue = this._restrictValue(formatted);

    // If value is empty, or initial value doesn't match constrained value
    if (!value || value !== constrainedValue) {
      this.input.value = constrainedValue;
    }

    return constrainedValue;
  }

  /**
   * Detach event handlers (in case this is used in a section)
   */
  unload() {
    this.decrementButton.removeEventListener('click');
    this.incrementButton.removeEventListener('click');
    this.input.removeEventListener('change', this._onInputChange);
  }
}
